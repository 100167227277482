import Vue from "vue";
import numeral from "numeral";

Vue.mixin({
  methods: {
    getProgress(type, progress, maxProgress) {


      const value = progress.toString().replace(/,/g, "");

      if (maxProgress === 0) {
        return 0;
      }

      if (type === 1) {
        return (Number(value) / maxProgress) * 100;
      } else if (type === 2) {
        return (Number(value) / maxProgress) * 100;
      } else if (type === 3) {
        return (Number(value) / maxProgress) * 100;
      } else {
        return Number(value) == maxProgress ? 100 : 0;
      }
    },
    getFormatMeasurement(type, value) {
      if (type === 1) {
        return `${numeral(value).format("0,0[.]00")}%`;
      } else if (type === 2) {
        return numeral(value).format("0,0.00");
      } else if (type === 3) {
        return `${numeral(value).format("0,0[.]00")} บาท`;
      } else {
        return numeral(value).format("0,0");
      }
    },
  },
});
