import Vue from "vue";
import { HTTP } from "@/service/axios.js";

Vue.mixin({
  computed: {
    limit() {
      return this.$store.state.limitHistory;
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {

    getNameCard(text, type) {
      if (Number(type) == 2) {
        return text.replace("ทาสก์", "ทอปปิก");
      } else {
        return text;
      }
    },
    sendNotiPushMemberOrAdmin(ownedBy, projectId, workSpaceId) {
      let obj = {
        projectId: projectId,
        workspaceId: workSpaceId,
        ownedBy: ownedBy,
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`noti/invite/member/admin`, obj);
    },
    sendNotichangetoAdmin(ownedBy, projectId, workSpaceId) {
      let obj = {
        projectId: projectId,
        workspaceId: workSpaceId,
        ownedBy: ownedBy,
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`noti/push/member/admin`, obj);
    },
  },
});
