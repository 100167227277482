<template>
  <div id="app" :class="{ 'bg-white': $route.meta.template === 'payment' }">
    <el-alert v-if="version" center class="demo-alert" type="error">
      <span slot="title">
        ลิงก์นี้เป็นเวอร์ชันใช้ "สำหรับทดลอง" ใช้งานระบบ MANA
        {{ version }} เท่านั้น หากคุณต้องการใช้ระบบเวอร์ชันใช้งานจริง
        <a href="https://manawork.com" target="_blank">
          <u>กรุณากดที่นี่</u>
        </a>
      </span>
    </el-alert>

    <el-dialog
      title=""
      :visible.sync="dialogVisibleUpgrade"
      width="50%"
      @closed="handleCloseUpgrade"
      class="dialog-upgrade"
    >
      <div v-if="formUpgrade.step === 1" class="step-promotion">
        <h2 class="title">อัปเกรดเป็น Premium User</h2>
        <el-row :gutter="20">
          <el-col :span="12"
            ><div
              class="promotion-card"
              :class="{ active: formUpgrade.promotion === 1 }"
            >
              <div class="content">
                <img src="@/assets/calendar30day.png" class="sidebar-logo" />
                <h2 class="pd-b-1">รายเดือน</h2>
                <h1 class="text-primary mg-t-less mg-b-5">
                  250 บาท / User / เดือน
                </h1>
                <span class="text-grey-8">(รวมภาษีมุลค่าเพิ่ม)</span>
                <div class="discount"></div>
              </div>
              <div @click="selectUpgrade(1)" class="action">เลือกแพคเกจ</div>
            </div></el-col
          >
          <el-col :span="12"
            ><div
              class="promotion-card"
              :class="{ active: formUpgrade.promotion === 2 }"
            >
              <div class="content">
                <img src="@/assets/calendar365day.png" class="sidebar-logo" />
                <h2 class="pd-b-1">รายปี</h2>
                <h1 class="text-primary mg-t-less mg-b-5">
                  2,700 บาท / User / ปี
                </h1>
                <span class="text-grey-8">(รวมภาษีมุลค่าเพิ่ม)</span>
                <div class="discount color">
                  <span>Save 10% (เดือนละ 225 บาท)</span>
                </div>
              </div>
              <div @click="selectUpgrade(2)" class="action">เลือกแพคเกจ</div>
            </div></el-col
          >
        </el-row>
      </div>
      <div v-else-if="formUpgrade.step === 2" class="step-user">
        <el-form :model="formUpgrade" :rules="rules" ref="form-upgrade">
          <div class="title">
            <div class="space">
              <i
                @click="formUpgrade.step = 1"
                class="el-icon-back cr-pointer"
              ></i>
            </div>

            <h3 class="text font-weight-bold">
              โปรโมชั่น {{ formUpgrade.promotion === 1 ? "รายเดือน" : "รายปี" }}
            </h3>
            <div class="space"></div>
          </div>
          <h3 class="font-weight-bold">
            โปรโมชั่น
            {{
              formUpgrade.promotion === 1
                ? "รายเดือน 250 บาท / User / เดือน"
                : "รายปี 2,700 บาท / User / ปี"
            }}
          </h3>
          <el-divider></el-divider>
          <div class="is-flex js-between">
            <p>กรุณาเลือกจำนวน Users</p>
            <el-form-item prop="numUser">
              <el-input-number
                v-model="formUpgrade.numUser"
                :min="1"
                :max="20"
              ></el-input-number>
            </el-form-item>
          </div>
          <el-divider></el-divider>
          <div class="t-right">
            <el-button @click="nextPayment" type="primary" size="medium"
              >ดำเนินการต่อ</el-button
            >
          </div>
        </el-form>
      </div>
      <div v-else-if="formUpgrade.step === 3" class="step-payment">
        <div class="title">
          <div class="space">
            <i
              @click="formUpgrade.step = 2"
              class="el-icon-back cr-pointer"
            ></i>
          </div>
          <div class="w-100"><img src="@/assets/logo-color.png" /></div>
          <div class="space"></div>
        </div>
        <h3 class="font-weight-bold">สรุปยอดชำระ</h3>
        <h1 class="text-primary mg-t-less mg-b-5">
          {{ getTotalPackage(formUpgrade) | comma }} บาท
        </h1>
        <span class="text-grey-8">(รวมภาษีมูลค่าเพิ่ม)</span>
        <el-divider></el-divider>
        <el-row :gutter="20" class="list">
          <el-col :span="3"><h3>โปรโมชั่น</h3></el-col>
          <el-col :span="11"
            ><h4>
              {{
                formUpgrade.promotion === 1
                  ? "รายเดือน 250 บาท / User / เดือน"
                  : "รายปี 2700 บาท / User / ปี"
              }}
            </h4>
            <p v-if="formUpgrade.promotion === 2" class="text-grey-light">
              Save 10% (เดือนละ 225 บาท)
            </p></el-col
          >
          <el-col :span="6" class="t-right"
            ><h4>
              {{ formUpgrade.promotion === 1 ? 250 : 2700 | comma }}
            </h4></el-col
          >
          <el-col :span="4"
            ><h4 class="text-grey-light">จำนวนเงิน (บาท)</h4></el-col
          >
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20" class="list">
          <el-col :span="3"><h3></h3></el-col>
          <el-col :span="11"><h4>จำนวน Users</h4> </el-col>
          <el-col :span="6" class="t-right"
            ><h4>{{ formUpgrade.numUser }}</h4></el-col
          >
          <el-col :span="4"
            ><h4 class="text-grey-light">จำนวน User (คน)</h4></el-col
          >
        </el-row>
        <el-divider></el-divider>
        <div class="is-flex js-between">
          <el-button
            @click="$store.commit('SET_DIALOG_UPGRADE', false)"
            type="danger"
            plain
            >ยกเลิกรายการ</el-button
          >
          <el-button
            @click="buyPackage"
            type="primary"
            size="medium"
            :loading="loadingPackage"
            >ดำเนินการต่อ</el-button
          >
        </div>
      </div>
      <div v-else-if="formUpgrade.step === 4" class="t-center">
        <el-button  type="primary" size="medium"
          >ดำเนินการต่อ</el-button
        >

        <h3 class="pd-b-1 mg-less">ชำระเงิน</h3>
        <el-row :gutter="40" class="pd-b-1">
          <el-col :span="12"
            ><div
              @click="paymentType = 1"
              class="check-radio"
              :class="{ active: paymentType === 1 }"
            >
              <div class="check">
                <i
                  v-if="paymentType === 1"
                  class="fa-solid fa-circle-check"
                ></i>
                <i v-else class="fa-regular fa-circle"></i>
              </div>
              <div class="icon">
                <i class="fa-sharp fa-solid fa-building-columns"></i>
              </div>

              <span class="text">บัญชีธนาคาร</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              @click="paymentType = 2"
              class="check-radio"
              :class="{ active: paymentType === 2 }"
            >
              <div class="check">
                <i
                  v-if="paymentType === 2"
                  class="fa-solid fa-circle-check"
                ></i>
                <i v-else class="fa-regular fa-circle"></i>
              </div>
              <div class="icon">
                <i class="fa-solid fa-credit-card"></i>
              </div>

              <span class="text">Pay solution</span>
            </div></el-col
          >
        </el-row>

        <div v-if="paymentType === 1">
          <img src="@/assets/logo-kbank.png" class="sidebar-logo" />
          <h3 class="pd-b-2">
            บัญชี ธนาคารกสิกรไทย ชื่อบัญชี บจก. แอลเอฟฟินเทค<br />สาขาแม่โจ้
            เลขที่บัญชี 792-1-01598-9
          </h3>
          <p class="text-grey-light pd-b-4">
            เมื่อชำระเงินเสร็จแล้ว กรุณาอัปโหลดหลักฐานการชำระเงินที่ เมนู
            อัปโหลดหลักฐานการชำระเงิน
          </p>
        </div>
        <div v-else>
          <el-form
            label-position="top"
            :model="formCreditCard"
            :rules="rulesCreditCard"
            ref="form-credit"
            class="validate-mg-less"
          >
            <el-row :gutter="20" class="pd-b-1">
              <el-col :span="12" class="t-left">
                <el-form-item label="Name" prop="name">
                  <el-input
                    v-model="formCreditCard.name"
                    placeholder="Name"
                    name="name"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12" class="t-left">
                <el-form-item label="Card Number" prop="number">
                  <el-input
                    v-model="formCreditCard.number"
                    placeholder="Card Number"
                    v-mask="'#### #### #### ####'"
                    name="number"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="12" class="t-left">
                <el-form-item label="Expiration Date" prop="expiration">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-select
                          v-model="formCreditCard.expirationMonth"
                          placeholder="MM"
                          name="expirationMonth"
                          @change="updateValidate"
                        >
                          <el-option
                            v-for="item in creditMonthOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-select
                          v-model="formCreditCard.expirationYear"
                          placeholder="YY"
                          name="expirationYear"
                          @change="updateValidate"
                        >
                          <el-option
                            v-for="item in creditYearOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select> </el-form-item
                    ></el-col>
                  </el-row> </el-form-item
              ></el-col>
              <el-col :span="12" class="t-left">
                <el-form-item label="CCV" prop="cvv">
                  <el-input
                    v-model="formCreditCard.cvv"
                    placeholder="CCV"
                    v-mask="'###'"
                    name="cvv"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="24" class="pd-t-2">
                <el-button
                  @click="addCreditCard"
                  :loading="loadingAddCreditCard"
                  type="primary"
                  size="medium"
                  class="w-100"
                  >ถัดไป</el-button
                ></el-col
              >
            </el-row>
          </el-form>
        </div>
      </div>
      <div v-else-if="formUpgrade.step === 5" class="t-center">
        <h3 class="pd-b-1 mg-less">ตรวจสอบคำสั่งซื้อ</h3>

        <div class="is-flex js-between pd-b-3">
          <span>ชื่อผู้รับ : </span>
          <span>บจก. แอลเอฟฟินเทค</span>
        </div>
        <div class="is-flex js-between pd-b-3">
          <span>ยอดคำสั่งซื้อ : </span>
          <span>{{ totalPaymant | comma }} บาท</span>
        </div>
        <div class="is-flex js-between pd-b-3">
          <span>วันที่ : </span>
          <span>{{ new Date() | dateFomatNumber }}</span>
        </div>
        <div class="is-flex js-between pd-b-3">
          <span>หมายเลขบัตร : </span>
          <span
            >{{ formCreditCard.number.slice(0, 4) }}********{{
              formCreditCard.number.slice(15, 19)
            }}</span
          >
        </div>
        <el-divider></el-divider>

        <el-button
          @click="checkOut"
          :loading="loadingCheckOut"
          type="primary"
          size="medium"
          class="w-100"
          >ชำระเงิน</el-button
        >
      </div>
      <div v-else class="step-credit-success">
        <div class="title">
          <div class="w-100"><img src="@/assets/logo-color.png" /></div>
        </div>
        <div class="body">
          <i class="fa-solid fa-circle-check"></i>
          <p class="text-1">{{ user.name }} {{ user.surname }}</p>
          <p class="text-2">การชำระเงินสำเร็จ</p>
          <p class="text-3">
            ขอบคุณสำหรับการชำระค่าบริการระดับ Premium<br />เราหวังว่า MANAWORK
            จะช่วยเพิ่มประสิทธิภาพให้กับการทำงานของคุณ
          </p>
        </div>
      </div>
    </el-dialog>

    <CheckOnline></CheckOnline>
    <router-view :key="$route.fullPath" />
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import CheckOnline from "@/components/checkOnline";
import { HTTP_GB, HTTP_MANA_BACK_OFFICE } from "@/service/axios.js";
import qs from "qs";

export default {
  name: "app",
  components: { CheckOnline },
  computed: {
    guideClassOpen() {
      return this.$store.state.guideClassOpen;
    },
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    dialogUpgrade() {
      return this.$store.state.dialogUpgrade;
    },
    workspace() {
      return this.$store.getters.getWorkspace(this.$route.params.workSpaceId);
    },
  },
  sockets: {
    connect() {
      // setInterval(() => {
      //  this.$socket.emit("interval");
      // }, 4000 * 60);
    },
    socketOnUpdateLoadData(data) {
      if (this.workspace && this.workspace._id === data.workspaceId) {
        this.$store.commit(
          "PULL_LOAD_DATA_SUCESSED",
          `${data.key}-${data.workspaceId}`
        );
      } else if (data.key === "member-goal") {
        this.$store.commit("PULL_LOAD_DATA_SUCESSED", data.key);
      } else {
        this.$store.commit("PULL_LOAD_DATA_SUCESSED", data.key);
      }
    },
    async socketOnPaymentDone(data) {
      if (this.user._id === data.userId) {
        if (data.resultCode === "00") {
          this.formUpgrade.step = 6;
          let obj = {
            token: this.token,
          };
          await this.$store.dispatch("FETCH_PAYMENT", obj);
        } else if (data.resultCode === "51") {
          this.alertPaymentCreditInsufficientFunds();
          this.dialogVisibleUpgrade = false;
        } else {
          this.alertPaymentCreditError();
          this.dialogVisibleUpgrade = false;
        }
      }
    },
  },

  watch: {
    dialogUpgrade() {
      this.dialogVisibleUpgrade = this.dialogUpgrade;
    },
  },
  data() {
    var validateExpiration = (rule, value, callback) => {
      if (
        !this.formCreditCard.expirationMonth ||
        !this.formCreditCard.expirationYear
      ) {
        callback(new Error("Please select Expiration Date."));
      } else {
        callback();
      }
    };
    return {
      rules: {
        numUser: [
          {
            required: true,
            message: "กรุณาเลือกจำนวน Users",
            trigger: "blur",
          },
        ],
      },
      version:
        process.env.NODE_ENV == "development"
          ? "v" +
            process.env.VUE_APP_VERSION +
            " [" +
            process.env.VUE_APP_GIT_HASH +
            "]"
          : "",
      formUpgrade: {
        step: 1,
        promotion: null,
        numUser: 1,
      },
      loadingPackage: false,
      dialogVisibleUpgrade: false,
      formCreditCard: {
        name: "",
        number: "",
        expirationMonth: "",
        expirationYear: "",
        cvv: "",
      },
      rulesCreditCard: {
        name: [
          {
            required: true,
            message: "Please input Name.",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "Please input Card Number.",
            trigger: "blur",
          },
          {
            min: 19,
            max: 19,
            message: "Please input valid Card Number.",
            trigger: "blur",
          },
        ],
        expiration: [{ validator: validateExpiration, trigger: "blur" }],
        cvv: [
          {
            required: true,
            message: "Please input CCV.",
            trigger: "blur",
          },
        ],
      },
      referenceNo: "",
      paymentType: 1,
      creditMonthOptions: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      creditYearOptions: [],
      loadingAddCreditCard: false,
      loadingCheckOut: false,
      totalPaymant: 0,
      tokenCheckOut: "",
    };
  },

  async created() {
    if (this.user) {
      setInterval(() => {
        this.checkRefreshToken();
      }, 1000 * 60 * 60 * 23.5);
    }

    for (let index = 0; index < 10; index++) {
      let year = `${new Date().getFullYear() + index}`;
      this.creditYearOptions.push(year.slice(2, 4));
    }
  },
  methods: {
    handleCloseUpgrade() {
      this.formUpgrade = {
        step: 1,
        promotion: null,
        numUser: 1,
      };
      this.$store.commit("SET_DIALOG_UPGRADE", false);

      this.formCreditCard = {
        name: "",
        number: "",
        expirationMonth: "",
        expirationYear: "",
        cvv: "",
      };
      this.loadingAddCreditCard = false;
      this.loadingCheckOut = false;
      this.paymentType = 1;
    },
    selectUpgrade(promotion) {
      this.formUpgrade = {
        step: 2,
        promotion: promotion,
        numUser: 1,
      };
    },
    async buyPackage() {
      try {
        this.loadingPackage = true;

        let obj = {
          packageCode: this.formUpgrade.promotion,
          user: this.formUpgrade.numUser,
          price: this.formUpgrade.promotion === 1 ? 250 : 2700,
          payment: null,
        };

        HTTP_MANA_BACK_OFFICE.defaults.headers.common.Authorization = `Bearer ${this.user.tokenBackoffice.data}`;
        let res = await HTTP_MANA_BACK_OFFICE.post(`api/payment`, obj);

        if (res.data.success) {
          this.totalPaymant = obj.price * obj.user;
          this.referenceNo = res.data.result.referenceNo;
          this.formUpgrade.step = 4;
          this.$store.commit("SET_REFRESH_PAYMENT", true);
          setTimeout(() => {
            this.$store.commit("SET_REFRESH_PAYMENT", false);
          }, 500);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingPackage = false;
      }
    },

    async addCreditCard() {
      try {
        this.$refs["form-credit"].validate().then(async (valid) => {
          if (valid) {
            this.loadingAddCreditCard = true;
            let publicKey = process.env.VUE_APP_GB_PUBLIC_KEY;

            let data = {
              rememberCard: false,
              card: {
                name: this.formCreditCard.name,
                number: this.formCreditCard.number,
                expirationMonth: this.formCreditCard.expirationMonth,
                expirationYear: this.formCreditCard.expirationYear,
                securityCode: this.formCreditCard.cvv,
              },
            };

            HTTP_GB.defaults.headers.common.Authorization = `Basic ${btoa(
              `${publicKey}:`
            )}`;

            let res = await HTTP_GB.post(
              process.env.VUE_APP_GB + "v2/tokens",
              data
            );

            if (res.data.resultCode == "00") {
              this.formUpgrade.step = 5;
              this.tokenCheckOut = res.data.card.token;
            } else if (res.data.resultCode == "02") {
              this.alertPaymentCreditDoNotHonour();
            } else if (res.data.resultCode == "54") {
              this.alertPaymentCreditExpiredCard();
            } else {
              this.alertPaymentCreditAddCardError();
            }
          }
        });
      } catch (e) {
        console.error("error", e);
      } finally {
        this.loadingAddCreditCard = false;
      }
    },
    async checkOut() {
      try {
        this.loadingCheckOut = true;
        let secretkey = process.env.VUE_APP_GB_SECRET_KEY;

        let urlBackoffice = process.env.VUE_APP_BASE_MANA_BACKOFFICE_URL;

        let obj = {
          amount:
            this.user.email === "pinyo.tadsomboon@gmail.com"
              ? 1
              : this.totalPaymant,
          referenceNo: this.referenceNo,
          detail: `แพ็คเกจใช้งาน MANA Premium User`,
          customerName: `${this.user.name} ${this.user.surname}`,
          customerEmail: this.user.email,
          customerAddress: "",
          customerTelephone: this.user.mobilephone,
          merchantDefined1: `คุณได้ซื้อแพ็คเกจใช้งาน Premium User ของ MANA แบบ${
            this.formUpgrade.promotion === 1 ? "รายเดือน" : "รายปี"
          }จำนวน ${this.formUpgrade.numUser} User`,
          card: {
            token: this.tokenCheckOut,
          },
          otp: "Y",
          responseUrl: `${urlBackoffice}api/payment/gb/response`,
          backgroundUrl: `${urlBackoffice}api/payment/gb/background`,
        };

        HTTP_GB.defaults.headers.common.Authorization = `Basic ${btoa(
          `${secretkey}:`
        )}`;

        let res = await HTTP_GB.post("v2/tokens/charge", obj);
        if (res.data.resultCode == "00") {
          this.secured(res.data.gbpReferenceNo);
        } else if (res.data.resultCode == "05") {
          this.alertPaymentCreditDoNotHonour();
        } else if (res.data.resultCode == "14") {
          this.alertPaymentCreditInvalidCardNumber();
        } else if (res.data.resultCode == "41" || res.data.resultCode == "43") {
          this.alertPaymentCreditCardCanceled();
        } else if (res.data.resultCode == "54") {
          this.alertPaymentCreditExpiredCard();
        } else {
          this.alertPaymentCreditError();
          this.dialogVisibleUpgrade = false;
        }
      } catch (e) {
        console.error("error", e);
      } finally {
        this.loadingCheckOut = false;
      }
    },
    async secured(gbpReferenceNo) {
      try {
        let publicKey = process.env.VUE_APP_GB_PUBLIC_KEY;

        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        let obj = {
          gbpReferenceNo: gbpReferenceNo,
          publicKey: publicKey,
        };

        let res = await HTTP_GB.post(
          process.env.VUE_APP_GB + "v2/tokens/3d_secured",
          qs.stringify(obj),
          config
        );

        var oWindow = window.open(
          "",
          "printWindow",
          "width=1200px,height=900px"
        );

        oWindow.document.open("text/html", "replace");
        oWindow.document.write(res.data);
        oWindow.document.write("<h4>กรุณารอสักครู่...</h4>");
        oWindow.document.close();
      } catch (e) {
        console.error("error", e);
      } finally {
        this.loadingCheckOut = false;
      }
    },

    nextPayment() {
      this.$refs["form-upgrade"].validate().then(async (valid) => {
        if (valid) {
          this.formUpgrade.step = 3;
        }
      });
    },
    getTotalPackage(formUpgrade) {
      let price = formUpgrade.promotion === 1 ? 250 : 2700;

      return formUpgrade.numUser * price;
    },
    updateValidate() {
      this.$refs["form-credit"].validateField("expiration");
    },
  },
};
</script>
