import Cookies from "js-cookie";
import { getToken } from "@/service/auth";
import province from "./constant/province";
import departments from "./constant/departments";
import positions from "./constant/positions";
import referCodes from "./constant/referCodes";
import teamSizes from "./constant/teamSizes";
import useForList from "./constant/useForList";
import usedOthers from "./constant/usedOthers";

const initState = () => ({
  token: getToken(),
  departments: departments,
  positions: positions,
  referCodes: referCodes,
  teamSizes: teamSizes,
  useForList: useForList,
  usedOthers: usedOthers,
  joinWorkSpace: [],
  userJoinWorkspace: [],
  user: null,
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : true,
    withoutAnimation: false,
  },
  limitHistory: 0,
  device: "desktop",
  showSettings: null,
  fixedHeader: false,
  sidebarLogo: false,
  commentFile: [],
  province: province,
  navbarTask: {
    type: "add",
    status: false,
  },
  taskNoti: "",
  taskList: [],
  members: [],
  Dashboard: [],
  filterDashboard: {
    period: 0,
  },
  projectStatus: [
    { label: "มีกำหนด", value: 1 },
    { label: "กำลังทำงาน", value: 2 },
    { label: "เสร็จสิ้นแล้ว", value: 3 },
    { label: "พักชั่วคราว", value: 4 },
    { label: "ถูกยกเลิก", value: 5 },
    { label: "ไม่มีสถานะ", value: 0 },
  ],
  workspaceList: [],
  workspaces: [],
  ruleform: {},
  businessType: [
    { id: 2, name: "การแปรรูปอาหาร (Food)", value: "" },
    { id: 6, name: "อิเล็กทรอนิกส์อัจฉริยะ (Smart Electronics)", value: "" },
    { id: 9, name: "ดิจิทอล (Digital)", value: "" },
    {
      id: 26,
      name: "เทคโนโลยีสารสนเทศและการสื่อสาร (Information & Communication Technology)",
      value: "",
    },
    { id: 17, name: "พาณิชย์หรือธุรกิจบริการออนไลน์ (Commerce)", value: "" },
    {
      id: 28,
      name: "ธุรกิจเพื่อผู้ประกอบธุรกิจ (Business-to-business)",
      value: "",
    },
    {
      id: 29,
      name: "ผลิตภัณฑ์ที่ใช้นวัตกรรมเป็นจุดขาย (Innovative Products)",
      value: "",
    },
    { id: 7, name: "หุ่นยนต์ (Robotics)", value: "" },
    {
      id: 22,
      name: "วัสดุอุตสาหกรรมและเครื่องจักร (Industrial Materials & Machine)",
      value: "",
    },
    { id: 21, name: "วัสดุก่อสร้าง (Construction Materials)", value: "" },
    {
      id: 20,
      name: "บริการรับเหมาก่อสร้าง (Construction Services)",
      value: "",
    },
    {
      id: 15,
      name: "ของใช้ในครัวเรือน หัตถกรรม และสำนักงาน (Lifestyle Products)",
      value: "",
    },
    { id: 18, name: "แฟชั่น สิ่งทอ เครื่องนุ่งห่ม (Fashion)", value: "" },
    { id: 30, name: "อัญมณีและเครื่องประดับ (Gem and Jewelry)", value: "" },

    { id: 27, name: "รับจ้างออกแบบ (Design Services)", value: "" },
    { id: 13, name: "บรรจุภัณฑ์ (Packaging)", value: "" },
    { id: 23, name: "สื่อและสิ่งพิมพ์ (Media & Publishing)", value: "" },
    {
      id: 5,
      name: "ยานยนต์สมัยใหม่ (Next – Generation Automotive)",
      value: "",
    },
    { id: 8, name: "การบินและโลจิสติกส์ (Aviation and Logistics)", value: "" },
    {
      id: 4,
      name: "เชื้อเพลิงชีวภาพและเคมีชีวภาพ (Biofuels and Biochemicals)",
      value: "",
    },
    {
      id: 3,
      name: "การท่องเที่ยวกลุ่มรายได้ดีและการท่องเที่ยวเชิงสุขภาพ (Affluent, Medical and Wellness Tourism)",
      value: "",
    },
    {
      id: 1,
      name: "การเกษตรและเทคโนโลยีชีวภาพ (Agriculture and Biotechnology)",
      value: "",
    },
    { id: 31, name: "นวัตกรรมยางพารา (Rubber Innovation)", value: "" },
    { id: 0, name: "การแพทย์ครบวงจร (Medical Hub)", value: "" },
    { id: 12, name: "ธุรกิจเครื่องสำอาง (Cosmetics)", value: "" },
    { id: 25, name: "ที่ปรึกษา (Consultant)", value: "" },
    { id: 19, name: "เงินทุนและหลักทรัพย์ (Finance & Securities)", value: "" },
    { id: 14, name: "ประกันภัยและประกันชีวิต (Insurance)", value: "" },
    { id: 16, name: "พัฒนาอสังหาริมทรัพย์ (Property Development)", value: "" },
    { id: 11, name: "การศึกษา (Education)", value: "" },
    { id: 24, name: "เหมืองแร่ (Mining)", value: "" },
    { id: 10, name: "ด้านอื่น ๆ", value: "" },
  ],
  defaultRole: [],
  permission: [],
  rolePermission: null,
  rateColorsEstimation: {
    1: "#BBF69D",
    2: "#6DDE33",
    3: "#84C5FF",
    4: "#1B7DD4",
    5: "#FFDB9B",
    6: "#FFBC45",
    7: "#FF7878",
    8: "#E20C0C",
  },
  rateTextEstimation: [
    "ความยาก: 1",
    "ความยาก: 3",
    "ความยาก: 5",
    "ความยาก: 8",
    "ความยาก: 13",
    "ความยาก: 20",
    "ความยาก: 40",
    "ความยาก: 100",
  ],
  rateColorsPriority: { 1: "#6DDE33", 2: "#FFBC45", 3: "#E20C0C" },
  rateClassPriority: { 1: "primary", 2: "warning", 3: "danger" },
  roleSet: {
    breadcrumbList: ["กำหนดสิทธิ์"],
    userSelect: null,
    guideList: [],
    guideClassOpen: "",
    userJoinProject: [],
    breadcrumbKPIs: [],
  },
  activities: [],
  breadcrumb: [],
  sortProject: 0,
  dialogUpgrade: false,
  loadDataSucceed: [],
  teams: [],
  reloadTask: false,
  memberOrganization: [],
  memberGoal: [],
  goalToggles: [],
  measurements: [
    { id: 1, name: "เปอร์เซ็นต์", icon: "fa-solid fa-percent" },
    { id: 2, name: "ตัวเลข", icon: "fa-solid fa-list-ol" },
    { id: 3, name: "จำนวนเงิน (บาท)", icon: "fa-solid fa-money-bill" },
    { id: 4, name: "จำนวนทาสก์ที่เสร็จสิ้น", icon: "fa-solid fa-check" },
  ],
  everyWeek: [
    { id: "week1", name: "ทุกวัน จันทร์" },
    { id: "week2", name: "ทุกวัน อังคาร" },
    { id: "week3", name: "ทุกวัน พุธ" },
    { id: "week4", name: "ทุกวัน พฤหัสบดี" },
    { id: "week5", name: "ทุกวัน ศุกร์" },
    { id: "week6", name: "ทุกวัน เสาร์" },
    { id: "week7", name: "ทุกวัน อาทิตย์" },
  ],
  everyMonth: [
    { id: "month1", name: "วันสุดท้ายของเดือน" },
    { id: "month2", name: "วันจันทร์แรกของเดือน" },
    { id: "month3", name: "วันศุกร์สุดท้ายของเดือน" },
  ],
  loadRefreshToken: false,
  refreshPayment: false,
  openNavWorkspace: false,
});

export default initState;
