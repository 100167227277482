export default [
  {
    value: 2,
    label: "Facebook",
  },
  {
    value: 8,
    label: "Youtube",
  },
  {
    value: 1,
    label: "Tiktok",
  },
  {
    value: 9,
    label: "LinkedIn",
  },
  {
    value: 6,
    label: "Search Engine",
  },
  {
    value: 11,
    label: "Blog",
  },
  {
    value: 10,
    label: "Seminar / Events",
  },
  {
    value: 3,
    label: "Friend Recommendation",
  },
  {
    value: 7,
    label: "Website Link",
  },
  {
    value: 999,
    label: "อื่นๆ",
  },
];
