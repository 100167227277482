<template>
  <div v-if="user">
    <el-alert
      v-if="!showBackOnline"
      title="You are now offline!"
      type="error"
      center
      show-icon
      closable
    ></el-alert>
    <audio v-if="isIdle" autoplay>
      <source
        src="@/assets/offline.mp3"
        type="audio/mp3"
        media="all and (min-width:680px)"
      />
    </audio>
  </div>
</template>

<script>
export default {
  name: "CheckOnline",
  data() {
    return {
      isStateIdle: false,
      idleTime: null,
      onLine: navigator.onLine,
      showBackOnline: true,
    };
  },
  watch: {
    onLine(v) {
      if (v && this.user) {
        this.showBackOnline = true;
      } else {
        this.showBackOnline = false;
      }
    },
    isIdle(newValue) {
      if (newValue && this.user) {
        this.isStateIdle = this.$loading({
          lock: true,
          text: "ZzZz..",
          spinner: "el-icon-mouse",
          background: "rgba(0, 0, 0, 0.7)",
        });

        let timerId = setInterval(() => {
          this.idleTime += 1000;
          if (!this.$store.state.idleVue.isIdle) {
            clearInterval(timerId);
            this.idleTime = null;
            this.$socket.emit("socketEmitOnlineStatus", {
              userId: this.user._id,
              status: 1,
            });
          }
          // if (this.time < 1) {
          //   clearInterval(timerId);
          //   // Your logout function should be over here
          //   // alert('logout user....');
          // }
          document.title =
            "ZzzZz.Z.. ~" + this.msToTime(this.idleTime) + " | MANA Platform";
          this.isStateIdle.text = "ZzZz.. ~" + this.msToTime(this.idleTime);
        }, 1000);

        this.$socket.emit("socketEmitOnlineStatus", {
          userId: this.user._id,
          status: 3,
        });
      } else {
        document.title = this.$route.meta.title;
        this.isStateIdle.close();
        this.$socket.emit("socketEmitOnlineStatus", {
          userId: this.user._id,
          status: 1,
        });
      }
    },
  },
  created() {
    window.addEventListener(
      "beforeunload",
      () => {
        this.$store.commit("SET_OPEN_NAV_WORKSPACE", false);
        this.$store.commit("RESET_LOAD_DATA_SUCESSED");
        this.$socket.emit("socketEmitOnlineStatus", {
          userId: this.user._id,
          status: 0,
        });
        this.workspaceList.map((item) => {
          this.$socket.emit("socketEmitLeftWorkspace", {
            workspaceId: item._id,
          });
        });
        this.$socket.emit("socketEmitLeftProject", {
          workspaceId: this.$route.params.workSpaceId,
          projectId: this.$route.params.projectId,
          userId: this.user._id,
        });
      },
      false
    );
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    if (this.user) {
      this.$socket.emit("socketEmitOnlineStatus", {
        userId: this.user._id,
        status: 1,
      });

      var hidden, visibilityState, visibilityChange;

      if (typeof document.hidden !== "undefined") {
        (hidden = "hidden"),
          (visibilityChange = "visibilitychange"),
          (visibilityState = "visibilityState");
      } else if (typeof document.msHidden !== "undefined") {
        (hidden = "msHidden"),
          (visibilityChange = "msvisibilitychange"),
          (visibilityState = "msVisibilityState");
      } else if (typeof document.webkitHidden !== "undefined") {
        (hidden = "webkitHidden"),
          (visibilityChange = "webkitvisibilitychange"),
          (visibilityState = "msVisibilityState");
      }

      var document_hidden = document[hidden];

      document.addEventListener(visibilityChange, () => {
        if (document_hidden != document[hidden]) {
          if (document[hidden]) {
            document.title = "I'm Busy :) - " + this.$route.meta.title;
            this.$socket.emit("socketEmitOnlineStatus", {
              userId: this.user._id,
              status: 2,
            });
          } else {
            document.title = this.$route.meta.title;
            this.$socket.emit("socketEmitOnlineStatus", {
              userId: this.user._id,
              status: 1,
            });
          }
          document_hidden = document[hidden];
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);

    this.workspaceList.map((item) => {
      this.$socket.emit("socketEmitLeftWorkspace", { workspaceId: item._id });
    });
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    msToTime(ms) {
      var milliseconds = parseInt((ms % 1000) / 100),
        seconds = Math.floor((ms / 1000) % 60),
        minutes = Math.floor((ms / (1000 * 60)) % 60),
        hours = Math.floor((ms / (1000 * 60 * 60)) % 24),
        days = Math.floor(ms / (1000 * 60 * 60 * 24));

      hours = hours > 0 ? hours + " Hrs " : "";
      minutes = minutes > 0 ? minutes + " Min " : "";
      seconds = seconds > 0 ? seconds + " Sec " : "";
      days = days > 0 ? days + " Days " : "";

      return days + hours + minutes + seconds;
    },
  },
  computed: {
    workspaceList() {
      return this.$store.state.workspaceList;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
