import Vue from "vue";
import { HTTP } from "@/service/axios.js";

Vue.mixin({
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    getRemaining(date) {
      let now = Date.now();
      let expiration = new Date(date).getTime();
      let time = (expiration - now) / 1000;
      let d = 0;
      let h = 0;
      let m = 0;

      d = parseInt(time / 86400);
      time = time - d * 86400;

      if (time > 0) {
        h = parseInt(time / 3600);
        time = time - h * 3600;

        if (time > 0) {
          m = parseInt(time / 60);
        }
      } else {
        return {
          d: "0",
          h: "00",
          m: "00",
        };
      }

      return {
        d: d,
        h: addZero(h),
        m: addZero(m),
      };

      function addZero(n) {
        return n < 10 ? `0${n}` : n;
      }
    },
    getPackageName(level) {
      if (level === 30 || level === 31) {
        return "Enterprise";
      } else if (level === 20 || level === 21) {
        return "Business";
      } else if (level === 10 || level === 11 || level === 12) {
        return "Premium";
      } else {
        return "";
      }
    },
    allowedToDo(role, data = null) {
      let allow = true;
      switch (role) {
        case "numWorkspace":
          allow = this.allowNumWorkspace();
          break;
        case "inviteGuest":
          allow = this.allowInviteGuest();
          break;
        case "uploadFile":
          allow = this.allowUploadFile(data.size);
          break;
        case "happiness":
          allow = this.allowHappiness();
          break;
        case "filterHistoryTask":
          allow = this.allowFilterHistoryTask();
          break;
        case "filterTask":
          allow = this.allowFilterTask();
          break;
        case "saveProject":
          allow = this.allowSaveProject();
          break;
        case "searchProject":
          allow = this.allowSearchProject();
          break;
        case "projectStatus":
          allow = this.allowProjectStatus();
          break;
        case "roleProject":
          allow = this.allowRoleProject();
          break;
        case "dashboard":
          allow = this.allowDashboard();
          break;
        case "themplateProject":
          allow = this.allowThemplateProject();
          break;
        case "moveProject":
          allow = this.allowMoveProject();
          break;
        case "fileProject":
          allow = this.allowFileProject();
          break;
        case "levelUserWorkspace":
          allow = this.allowLevelUserWorkspace();
          break;
        case "feedActivity":
          allow = this.allowFeedActivity();
          break;
        case "exportTimeline":
          allow = this.allowExportTimeline();
          break;
        case "searchMember":
          allow = this.allowSearchMember();
          break;
        case "sortProject":
          allow = this.allowSortProject();
          break;
        case "settingProjectType":
          allow = this.allowSettingProjectType();
          break;
        case "searchFile":
          allow = this.allowSearchFile();
          break;
        case "goal":
          allow = this.allowGoal();
          break;
        case "createTeam":
          allow = this.allowCreateTeam();
          break;
        case "timeline":
          allow = this.allowTimeline();
          break;
        case "editRangeDate":
          allow = this.allowEditRangeDate();
          break;
        case "recoveryTrashcan":
          allow = this.allowRecoveryTrashcan();
          break;
        default:
          break;
      }

      return allow;
    },
    allowNumWorkspace() {
      if (
        this.user &&
        this.user.level === 0 &&
        this.user.createdWorkspace >= 1
      ) {
        return false;
      }
      return true;
    },
    allowInviteGuest() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowUploadFile(size) {
      if (this.user && this.user.level === 0 && size > 2000000) {
        return false;
      } else if (this.user && this.user.level > 0 && size > 100000000) {
        return false;
      }
      return true;
    },
    allowHappiness() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowFilterHistoryTask() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowFilterTask() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSaveProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSearchProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowProjectStatus() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowRoleProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowDashboard() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowThemplateProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowMoveProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowFileProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowLevelUserWorkspace() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowFeedActivity() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowExportTimeline() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSearchMember() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSortProject() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSettingProjectType() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowSearchFile() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowGoal() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowCreateTeam() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowTimeline() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowEditRangeDate() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
    allowRecoveryTrashcan() {
      if (this.user && this.user.level === 0) {
        return false;
      }
      return true;
    },
  },
});
