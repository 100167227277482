// @ts-nocheck
import { HTTP } from "@/service/axios";

export default {
  FETCH_MEMBER_WORKSPACE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`get/user/in/workspace/${obj.workspaceId}`)
      .then(async (res) => {
        if (res.data.success) {
          await commit("PUSH_LOAD_DATA_SUCESSED", `members-${obj.workspaceId}`);
          await commit("UPDATE_MEMBERS", {
            workspaceId: obj.workspaceId,
            data: res.data.obj,
          });
        }
      })
      .catch((e) => {
        console.error("FETCH_MEMBER_WORKSPACE", e);
      });
  },
  FETCH_WORKSPACE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`get/my/workspace`)
      .then(async (res) => {
        if (res.data.success) {
          await commit("SET_WORKSPACE_LIST", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_WORKSPACE", e.response);
      });
  },

  FETCH_COMMENT_FILE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;

    await HTTP.get(`get/comment/file/${obj.id}`)
      .then(async (res) => {
        if (res.data.success) {
          commit("SET_COMMENT_FILE", res.data.obj);
        } else {
          commit("SET_COMMENT_FILE", []);
        }
      })
      .catch((e) => {
        console.error("FETCH_COMMENT_FILE error", e.response);
      });
  },
  FETCH_DEFAULT_ROLE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`default/role/all`)
      .then(async (res) => {
        if (res.data.success) {
          await commit(
            "PUSH_LOAD_DATA_SUCESSED",
            `defaultRole-${obj.workspaceId}`
          );
          await commit("SET_DEFAULT_ROLE", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_DEFAULT_ROLE error", e.response);
      });
  },
  FETCH_GUIDE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`tour/guide`)
      .then((res) => {
        if (res.data.success) {
          commit("SET_GUIDE_LIST", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_GUIDE error", e.response);
      })
      .finally(() => {
        return true;
      });
  },
  SET_GUIDE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.post(`tour/guide`, obj.data).catch((e) => {
      console.error("SET_GUIDE error", e.response);
    });
  },
  FETCH_ACTIVITIES_WORKSPACE: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.post(`get/activities/workspace`, obj.data)
      .then(async (res) => {
        if (res.data.success) {
          await commit("SET_ACTIVITIES", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_ACTIVITIES_WORKSPACE", e);
      });
  },
  FETCH_PAYMENT: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`get/user/payment`)
      .then(async (res) => {
        if (res.data.success) {
          await commit("SET_USER_KEY", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_PAYMENT", e.response);
      });
  },
  FETCH_TEAMS: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`get/all/tag/team/${obj.workspaceId}`)
      .then(async (res) => {
        if (res.data.success) {
          await commit("PUSH_LOAD_DATA_SUCESSED", `teams-${obj.workspaceId}`);
          await commit("UPDATE_TEAMS", {
            workspaceId: obj.workspaceId,
            data: res.data.obj,
          });
        }
      })
      .catch((e) => {
        console.error("FETCH_TEAMS", e.response);
      });
  },
  FETCH_MEMBER_GOAL: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`members/goal`)
      .then(async (res) => {
        if (res.data.success) {
          await commit("PUSH_LOAD_DATA_SUCESSED", `member-goal`);
          await commit("SET_MEMEBER_GOAL", res.data.obj);
        }
      })
      .catch((e) => {
        console.error("FETCH_MEMBER_GOAL", e.response);
      });
  },
  FETCH_MEMBERS_ORGANIZATION: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`goal/organizations/members/${obj.goalId}`)
      .then(async (res) => {
        if (res.data.success) {
          await commit(
            "PUSH_LOAD_DATA_SUCESSED",
            `member-organization-${res.data.obj.organizationId}`
          );
          await commit("UPDATE_MEMBERS_ORGANIZATION", {
            organizationId: res.data.obj.organizationId,
            data: res.data.obj.users,
          });
        }
      })
      .catch((e) => {
        console.error("FETCH_MEMBERS_ORGANIZATION", e.response);
      });
  },
};
