export default [
  {
    value: 1,
    label: "การตลาด",
  },
  {
    value: 2,
    label: "การเงิน",
  },
  {
    value: 3,
    label: "การผลิต",
  },
  {
    value: 4,
    label: "ออกแบบและสร้างสรรค์",
  },
  {
    value: 5,
    label: "ทรัพยากรบุคคล",
  },
  {
    value: 6,
    label: "ไอทีและสการสนับสนุน",
  },
  {
    value: 7,
    label: "บริการลูกค้า",
  },
  {
    value: 999,
    label: "อื่นๆ",
  },
];
