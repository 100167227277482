export default [
  {
    value: 1,
    label: "ทรัพยากรบุคคล",
  },
  {
    value: 2,
    label: "สำนักงานบัญชี",
  },
  {
    value: 3,
    label: "ธุรกิจ IT และ Software House",
  },
  {
    value: 4,
    label: "ออกแบบและดีไซน์",
  },
  {
    value: 5,
    label: "ธุรกิจออนไลน์",
  },
  {
    value: 6,
    label: "ธุรกิจรับเหมาสร้างบ้าน/อาคาร",
  },
  {
    value: 7,
    label: "โรงงานอุตสาหกรรมและการผลิต",
  },
  {
    value: 8,
    label: "ธุรกิจเพื่อสุขภาพ",
  },
  {
    value: 999,
    label: "อื่นๆ",
  },
];
