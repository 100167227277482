import Vue from "vue";
import { HTTP_GB, HTTP_MANA_BACK_OFFICE, HTTP } from "@/service/axios.js";
import router from "../router";

Vue.mixin({
  computed: {
    workspaceListConst() {
      return this.$store.state.workspaceList;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async signout() {
      this.$socket.emit("socketEmitOnlineStatus", {
        userId: this.user._id,
        status: 0,
      });
      this.workspaceListConst.map((item) => {
        this.$socket.emit("leftWorkspace", { workspaceId: item._id });

        if (
          typeof this.$route.params.projectId != "undefined" &&
          typeof this.$route.params.workSpaceId != "undefined"
        ) {
          this.$socket.emit("leftProject", {
            workspaceId: this.$route.params.workSpaceId,
            projectId: this.$route.params.projectId,
            userId: this.user._id,
          });
        }
      });
      this.$socket.emit("leftSystem");
      setTimeout(() => {
        window.localStorage["ig-en"].clear();
      }, 200);
      this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("ig-en");
      delete HTTP.defaults.headers.common.Authorization;
      delete HTTP_MANA_BACK_OFFICE.defaults.headers.common.Authorization;
      delete HTTP_GB.defaults.headers.common.Authorization;
      setTimeout(() => {
        if (router.history.current.fullPath.indexOf("signout") > -1) {
          window.location.href = `/signin?redirect=${router.history.current.query.redirect}`;
        } else {
          window.location.href = `/signin?redirect=${router.history.current.fullPath}`;
        }
      }, 500);
    },
    async refreshToken() {
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.user.refreshToken.data}`;
        let res = await HTTP.post(`/auth/refresh`);

        if (res.data.success) {
          this.$store.commit("SET_TOKEN", res.data.obj.token.data);
          this.$store.commit("SET_USER_KEY", res.data.obj);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async checkRefreshToken() {
      if (
        this.user &&
        Date.now() > this.user.token.exp - 1000 * 60 * 60 * 23.5
      ) {
        await this.refreshToken();

        return true;
      } else {
        return false;
      }
    },
  },
});
