import axios from "axios";
import store from "../store";
import router from "../router";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1000 * 60 * 2,
});

const HTTP_MANA_BACK_OFFICE = axios.create({
  baseURL: process.env.VUE_APP_BASE_MANA_BACKOFFICE_URL,
  timeout: 1000 * 60 * 2,
});

const HTTP_GB = axios.create({
  baseURL: process.env.VUE_APP_GB,
  timeout: 1000 * 60 * 2,
});

const HTTP_TOKEN = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1000 * 60 * 2,
});

// Add a request interceptor
HTTP.interceptors.response.use(
  async function (response) {
    // Do something before request is sent

    return response;
  },
  async function (error) {
    if (error.response.data.status === 401) {
      await checkRefreshToken();
    } else if (
      error.response.data.status === 403 &&
      error.response.data.data == "Account is deleted"
    ) {
      window.location.href = "/account/deleted";
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

const checkRefreshToken = async () => {
  try {
    HTTP_TOKEN.defaults.headers.common.Authorization = `Bearer ${store.state.user.refreshToken.data}`;
    let res = await HTTP_TOKEN.post(`/auth/refresh`);

    if (res.data.success) {
      store.commit("SET_TOKEN", res.data.obj.token.data);
      store.commit("SET_USER_KEY", res.data.obj);
      location.reload();
    }
    return true;
  } catch (error) {
    console.error("error", error);
    if (error.response.data.status === 401) {
      window.location.href = `/signout?redirect=${router.history.current.fullPath}`;
    }
    return true;
  }
};

export { HTTP, HTTP_MANA_BACK_OFFICE, HTTP_GB };
