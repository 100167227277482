import Cookies from "js-cookie";
import { setToken, removeToken } from "@/service/auth";
import initState from "./initState";

export default {
  SET_STATE: (state, payload) => {
    let index = state.joinWorkSpace.findIndex((d) => d._id == payload._id);
    if (index > -1) {
      state.joinWorkSpace.splice(index, 1);
    } else {
      state.joinWorkSpace.push(payload.value);
    }
  },
  TOGGLE_SIDEBAR: (state) => {
    let obj = {
      opened: !state.sidebar.opened,
      withoutAnimation: false,
    };
    state.sidebar = obj;

    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: (state) => {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = false;
  },
  TOGGLE_DEVICE: (state, payload) => {
    state.device = payload;
  },
  SET_LIMITHISTORY: (state, payload) => {
    state.limitHistory = payload;
  },
  SET_USER_KEY: (state, payload) => {
    Object.keys(payload).forEach((key) => {
      state.user[key] = payload[key];
    });
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_TOKEN: (state, payload) => {
    setToken(payload);
    state.token = payload;
  },
  SIGNOUT: (state) => {
    removeToken();
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_NAVBATTASK: (state, payload) => {
    state.navbarTask = payload;
  },
  SET_DASHBOARD: (state, payload) => {
    state.Dashboard = payload;
  },
  SET_FILTERDASHBOARD: (state, payload) => {
    state.filterDashboard[payload.type] = payload.value;
  },
  SET_TASK: (state, payload) => {
    state.taskList = payload;
  },
  CLEAR_TASK: (state) => {
    state.taskList = {};
  },
  REMOVE_MEMBERS: (state, payload) => {
    let index = state.members.findIndex(
      (f) => f.workspaceId === payload.workspaceId
    );

    if (index > -1) {
      let indexMemeber = state.members[index].members.findIndex(
        (f) => f._id === payload.userId
      );

      if (indexMemeber > -1) {
        state.members[index].members.splice(indexMemeber, 1);
      }
    }
  },
  UPDATE_MEMBERS: (state, payload) => {
    let index = state.members.findIndex(
      (f) => f.workspaceId === payload.workspaceId
    );

    let members = [];
    payload.data.admins.map((item) => {
      item["role"] = "admin";
      members.push(item);
    });

    payload.data.members.map((item) => {
      item["role"] = "member";
      members.push(item);
    });

    payload.data.guest.map((item) => {
      item["role"] = "guest";
      members.push(item);
    });

    let obj = { workspaceId: payload.workspaceId, members: members };

    if (index < 0) {
      state.members.push(obj);
    } else {
      state.members.splice(index, 1);
      state.members.push(obj);
    }
  },
  SET_WORKSPACE_LIST: (state, payload) => {
    state.workspaceList = payload;
  },
  UPDATE_WORKSPACE_LIST_KEY_VALUE: (state, payload) => {
    let index = state.workspaceList.findIndex((f) => f._id === payload._id);

    if (index > -1) {
      Object.keys(payload.data).forEach((key) => {
        state.workspaceList[index][key] = payload.data[key];
      });
    }
  },
  PULL_WORKSPACE_LIST: (state, payload) => {
    let index = state.workspaceList.findIndex((f) => f._id === payload._id);
    if (index > -1) {
      state.workspaceList.splice(index, 1);
    }
  },
  UPDATE_WORKSPACE: (state, payload) => {
    let index = state.workspaces.findIndex((f) => f._id === payload._id);

    if (index < 0) {
      state.workspaces.push(payload);
    } else {
      state.workspaces.splice(index, 1);
      state.workspaces.push(payload);
    }
  },
  UPDATE_WORKSPACE_KEY_VALUE: (state, payload) => {
    let index = state.workspaces.findIndex((f) => f._id === payload._id);

    if (index > -1) {
      Object.keys(payload.data).forEach((key) => {
        state.workspaces[index][key] = payload.data[key];
      });
    }
  },
  PULL_WORKSPACE: (state, payload) => {
    let index = state.workspaces.findIndex((f) => f._id === payload._id);
    if (index > -1) {
      state.workspaces.splice(index, 1);
    }
  },
  SET_RULEFORM: (state, payload) => {
    state.ruleform = payload;
  },
  CLEAR_RULEFORM: (state) => {
    state.ruleform = {};
  },
  SET_COMMENT_FILE: (state, payload) => {
    state.commentFile = payload;
  },
  UPDATE_COMMENT_FILE: (state, payload) => {
    let index = state.commentFile.findIndex((f) => f._id === payload._id);

    if (index < 0) {
      state.commentFile.push(payload);
    } else {
      state.commentFile.splice(index, 1);
      state.commentFile.push(payload);
    }
  },
  UPDATE_COMMENT_FILE_KEY: (state, payload) => {
    let index = state.commentFile.findIndex((f) => f._id === payload._id);

    if (index > -1) {
      Object.keys(payload.data).forEach((key) => {
        state.commentFile[index][key] = payload.data[key];
      });
    }
  },
  UPDATE_FILE_INDEX: (state, payload) => {
    payload.files.map((item) => {
      let index = state.commentFile.findIndex((f) => f._id === item._id);
      if (index > -1) {
        state.commentFile[index]["index"] = item.index;
      }
    });
  },
  RESET_FILE_COVER: (state) => {
    state.commentFile = state.commentFile.map((item) => {
      if (typeof item.cover !== "undefined" && item.cover) {
        item.cover = false;
      }
      return item;
    });
  },
  DELETE_COMMENT_FILE: (state, payload) => {
    let index = state.commentFile.findIndex((f) => f._id === payload._id);

    if (index > -1) {
      state.commentFile.splice(index, 1);
    }
  },
  SET_DEFAULT_ROLE: (state, payload) => {
    state.defaultRole = payload;
  },
  SET_PERMISSION: (state, payload) => {
    state.permission = payload;
  },
  SET_ROLE_PERMISSION: (state, payload) => {
    state.rolePermission = payload;
  },
  SET_ROLE_BREADCRUMB_LIST: (state, payload) => {
    state.roleSet.breadcrumbList = payload;
  },
  SET_ROLE_USER_SELECT: (state, payload) => {
    state.roleSet.userSelect = payload;
  },
  SET_GUIDE_LIST: (state, payload) => {
    state.guideList = payload;
  },
  SET_GUIDE_ClASS_OPEN: (state, payload) => {
    state.guideClassOpen = payload;
  },
  SET_USER_JOIN_PROJECT: (state, payload) => {
    state.userJoinProject = payload;
  },
  UPDATE_USER_JOIN_WORKSPACE: (state, payload) => {
    let index = state.userJoinWorkspace.findIndex((a) => a._id === payload._id);
    if (index > -1) {
      state.userJoinWorkspace[index] = payload;
    } else {
      state.userJoinWorkspace.push(payload);
    }
  },

  SET_TASK_NOTI: (state, payload) => {
    state.taskNoti = payload;
  },
  SET_BREADCRUMB_KPI: (state, payload) => {
    state.breadcrumbKPIs = payload;
  },
  SET_ACTIVITIES: (state, payload) => {
    state.activities = payload;
  },
  SET_BREADCRUMB: (state, payload) => {
    state.breadcrumb = payload;
  },
  SET_SORT_PROJECT: (state, payload) => {
    state.sortProject = payload;
  },
  SET_DIALOG_UPGRADE: (state, payload) => {
    state.dialogUpgrade = null;
    setTimeout(() => {
      state.dialogUpgrade = payload;
    }, 300);
  },
  RESET_LOAD_DATA_SUCESSED: (state) => {
    state.loadDataSucceed = [];
  },
  PUSH_LOAD_DATA_SUCESSED: (state, payload) => {
    let find = state.loadDataSucceed.find((f) => f === payload);
    if (find === undefined) {
      state.loadDataSucceed.push(payload);
    }
  },
  PULL_LOAD_DATA_SUCESSED: (state, payload) => {
    let index = state.loadDataSucceed.findIndex((f) => f === payload);
    if (index > -1) {
      state.loadDataSucceed.splice(index, 1);
    }
  },
  SET_TEAMS: (state, payload) => {
    state.teams = payload;
  },
  UPDATE_TEAMS: (state, payload) => {
    let index = state.teams.findIndex(
      (f) => f.workspaceId === payload.workspaceId
    );
    let obj = { workspaceId: payload.workspaceId, teams: payload.data };

    if (index < 0) {
      state.teams.push(obj);
    } else {
      state.teams.splice(index, 1);
      state.teams.push(obj);
    }
  },
  SET_RELOAD_TASK: (state, payload) => {
    state.reloadTask = payload;
  },
  SET_MEMEBER_GOAL: (state, payload) => {
    state.memberGoal = payload.map((item) => {
      return {
        _id: item._id,
        name: `${item.name} ${item.surname}`,
        email: item.email,
        profileImg: item.profileImg,
      };
    });
  },
  OPEN_GOAL_TOGGLES: (state, payload) => {
    const index = state.goalToggles.findIndex((f) => f === payload);
    if (index === -1) {
      state.goalToggles.push(payload);
    }
  },
  UPDATE_GOAL_TOGGLES: (state, payload) => {
    const index = state.goalToggles.findIndex((f) => f === payload);
    if (index > -1) {
      state.goalToggles.splice(index, 1);
    } else {
      state.goalToggles.push(payload);
    }
  },
  SET_GOAL_TOGGLES: (state, payload) => {
    const index = state.goalToggles.findIndex((f) => f === payload);
    if (index > -1) {
      state.goalToggles[index] = payload;
    } else {
      state.goalToggles.push(payload);
    }
  },
  DELETE_MEMBERS_ORGANIZATION: (state, payload) => {
    let index = state.memberOrganization.findIndex(
      (f) => f.organizationId === payload.organizationId
    );

    if (index > -1) {
      state.memberOrganization.splice(index, 1);
    }
  },
  UPDATE_MEMBERS_ORGANIZATION: (state, payload) => {
    let index = state.memberOrganization.findIndex(
      (f) => f.organizationId === payload.organizationId
    );

    payload.data = payload.data.map((item) => {
      return {
        _id: item._id,
        name: `${item.name} ${item.surname}`,
        email: item.email,
        profileImg: item.profileImg,
      };
    });

    let obj = { organizationId: payload.organizationId, members: payload.data };

    if (index < 0) {
      state.memberOrganization.push(obj);
    } else {
      state.memberOrganization.splice(index, 1);
      state.memberOrganization.push(obj);
    }
  },
  SET_LOAD_REFRESH_TOKEN: (state, payload) => {
    state.loadRefreshToken = payload;
  },
  SET_REFRESH_PAYMENT: (state, payload) => {
    state.refreshPayment = payload;
  },
  SET_OPEN_NAV_WORKSPACE: (state, payload) => {
    state.openNavWorkspace = payload;
  },
};
