import Vue from "vue";

Vue.mixin({
  methods: {
    getColorProjectStatus(value) {
      let color = "";
      switch (value) {
        case 0:
          color = "#ffffff";
          break;
        case 1:
          color = "#1B7DD4";
          break;
        case 2:
          color = "#FFBC45";
          break;
        case 3:
          color = "#6DDE33";
          break;
        case 4:
          color = "#C3C3C3";
          break;
        case 5:
          color = "#E20C0C";
          break;
        case 6:
          color = "#27b6ba";
          break;
        default:
          color = "#fff";
          break;
      }
      return color;
    },
    getClassStatus(value) {
      let color = "";
      switch (value) {
        //ไม่มีสถานะ
        case 0:
          color = "btn-dashed";
          break;
        //มีกำหนด
        case 1:
          color = "btn-warning";
          break;
        //กำลังทำงาน
        case 2:
          color = "btn-process";
          break;
        //เสร็จสิ้นแล้ว
        case 3:
          color = "btn-success";
          break;
        //พักชั่วคราว
        case 4:
          color = "btn-pause";
          break;
        //ถูกยกเลิก
        case 5:
          color = "btn-cancle";
          break;
        //จัดเก็บ
        case 6:
          color = "btn-save";
          break;
        //ไม่มีสถานะ
        default:
          color = "btn-dashed";
          break;
      }
      return color;
    },
    customGoalStatusColor(percentage) {
      if (percentage < 1) {
        return "#D9D9D9";
      } else if (percentage <= 25) {
        return "#EB1C25";
      } else if (percentage <= 50) {
        return "#F6BA0D";
      } else if (percentage <= 75) {
        return "#42DBAD";
      } else if (percentage <= 100) {
        return "#3CAFFE";
      } else {
        return "#1B67F2";
      }
    },
    getColorProgress(percent) {
      if (percent <= 20) {
        return "#E20C0C";
      } else if (percent <= 40) {
        return "#e6a23c";
      } else if (percent <= 60) {
        return "#e6a23c";
      } else if (percent <= 80) {
        return "#5cb87a";
      } else {
        return "#5cb87a";
      }
    },
  },
});
