import Vue from "vue";

Vue.mixin({
  computed: {
    positions() {
      return this.$store.state.positions;
    },
    departments() {
      return this.$store.state.departments;
    },
  },
  methods: {
    getPosition(value, more) {
      if (value === 999) {
        return more;
      }
      const position = this.positions.find((f) => f.value === value);
      if (typeof position !== "undefined") {
        return position.label;
      }
    },
    getDepartments(value, more) {
      if (value === 999) {
        return more;
      }
      const department = this.departments.find((f) => f.value === value);
      if (typeof department !== "undefined") {
        return department.label;
      }
    },
  },
});
