import Vue from "vue";

Vue.mixin({
    computed: {
        loadDataSucceed() {
            return this.$store.state.loadDataSucceed
        }
    },
    methods: {
        haveUpdate(key) {

            let find = this.loadDataSucceed.find((f) => f === key)
            if (find === undefined) {
                return true
            } else {
                return false
            }
        }
    },
});
