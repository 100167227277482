// @ts-nocheck
import Vue from "vue";
import ElementUI from "element-ui";

import "./element-variables.scss";

import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "normalize.css/normalize.css";

import "@/styles/index.scss";
import "@/icons";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import locale from "element-ui/lib/locale/lang/en";
import moment from "moment";
import momentTH from "moment/src/locale/th";
import VueTheMask from "vue-the-mask";
// import VueSocketio from 'vue-socket.io-extended';
import VueSocketio from "vue-socket.io";
import io from "socket.io-client";
import "element-ui/lib/theme-chalk/display.css";
import InfiniteLoading from "vue-infinite-loading";
import VueDragscroll from "vue-dragscroll";

import VueAnalytics from "vue-analytics";
import IdleVue from "idle-vue";
import VueQuillEditor from "vue-quill-editor";
// import 'quill/dist/quill.core.css'
import "quill/dist/quill.snow.css";
import "element-ui/lib/theme-chalk/display.css";

import numeral from "numeral";
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueDataTables from "vue-data-tables";
import VueNativeNotification from "vue-native-notification";
// service worker
import "./registerServiceWorker";
import VuePwaInstallPlugin from "vue-pwa-install";

//service
import "@/service/version";
import "@/service/roleUse";
import "@/service/alertMsg";
import "@/service/notiRealTime";
import "@/service/date";
import "@/service/loadData";
import "@/service/number";
import "@/service/status";
import "@/service/authention";
import "@/service/user";

import Vqr from "v-qr";
Vue.use(Vqr);

Vue.use(VuePwaInstallPlugin);
Vue.use(VueDataTables);
Vue.use(VueQuillEditor);
Vue.use(VueDragscroll);
Vue.use(InfiniteLoading);
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
});
Vue.filter("comma", (value) => {
  return numeral(value).format("0,0.00");
});

Vue.filter("commaZero", (value) => {
  return numeral(value).format("0,0");
});

Vue.filter("rangeFromNow", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH)).fromNow();
  }
  return res;
});

Vue.filter("dateFomatNumber", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format("L");
  }
  return res;
});

Vue.filter("dateFomatTime", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "LTS น"
    );
  }
  return res;
});

Vue.filter("dateTimeDash", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "DD MMM YY LT"
    );
  }
  return res;
});

Vue.filter("dateTimeNoti", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "DD MMM Y เวลา LT น."
    );
  }
  return res;
});

Vue.filter("dateTimeNotiDown7", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(
      moment(date).locale("th", momentTH).add(543, "y").add(-7, "hours")
    ).format("DD MMM Y เวลา LT น.");
  }
  return res;
});

Vue.filter("dateFomatNotTime", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "DD MMMM Y"
    );
  }
  return res;
});

Vue.filter("dateFomatDay", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(date).locale("th", momentTH).format("dddd");
  }
  return res;
});

Vue.filter("dateFomatNotTimeEn", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).format("DD MMMM Y"));
  }
  return res;
});

Vue.filter("dateFomatShortmonth", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "ll"
    );
  }
  return res;
});

Vue.filter("dateFomatShortmonthTime", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "lll น"
    );
  }
  return res;
});

Vue.filter("dateTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "LL"
    );
  }
  return res;
});

Vue.filter("dateTimeTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "lll น."
    );
  }
  return res;
});

Vue.filter("dateTimeThFull", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "LLL น."
    );
  }
  return res;
});

Vue.filter("formatDateMonthNumber", (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return "-";
  } else {
    // moment.locale('en-au')
    return moment(moment(value).locale("th", momentTH).add(543, "y")).format(
      "DD-MM-YYYY LTS"
    );
  }
});

Vue.filter("formatDateMonthNumberNotTime", (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return "-";
  } else {
    // moment.locale('en-au')
    return moment(moment(value).locale("th", momentTH).add(543, "y")).format(
      "DD-MM-YYYY"
    );
  }
});

Vue.filter("formatDateTimeComment", (value) => {
  if (typeof value === "undefined" || value === null || value === "") {
    return "-";
  } else {
    // moment.locale('en-au')
    return moment(moment(value).utcOffset(7)).calendar(null, {
      sameElse: "MMM D, YYYY [at] h:mm A",
    });
  }
});

Vue.filter("camelCase", function (str) {
  // return str.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
  return str.replace(/([A-Z]+)/g, " $1");
});

Vue.filter("subStrmini", (value) => {
  if (value != undefined) {
    let cut = 28;
    let numSliceFrist = value.slice(0, cut);
    let regReplaceFrist = numSliceFrist.replace(
      /[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,
      ""
    );
    let numPoolFrist = numSliceFrist.length - regReplaceFrist.length;

    let numSliceLast = value.slice(cut, cut + numPoolFrist);
    let regReplaceLast = numSliceLast.replace(
      /[์ ื ิ ๋ ่ ็ ้ ๊ ี ํ ั ึ ู ุ]/g,
      ""
    );
    let numPoolLast = numSliceLast.length - regReplaceLast.length;

    const char = value.length;
    let charTotal = cut + numPoolFrist + numPoolLast;

    if (char <= charTotal) {
      return value;
    }
    return `${value.slice(0, charTotal)}...`;
  }
});

let handleOutsideClick;

Vue.directive("closable", {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },

  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === "production";
Vue.config.performance = !isProd;

Vue.use(VueAnalytics, {
  id: "UA-113888136-3",
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd,
  },
});

// Vue.use(VueFbCustomerChat, {
//   page_id: 101898464796414, //  change 'null' to your Facebook Page ID,
//   theme_color: '#1b6dd5', // theme color in HEX
//   locale: 'th_TH', // default 'en_US'
//   logged_in_greeting: 'สวัสดีครับ วันนี้มีอะไรให้เราช่วยเหลือไหมครับ?',
//   logged_out_greeting: 'สวัสดีครับ วันนี้มีอะไรให้เราช่วยเหลือไหมครับ?',
// });

Vue.use(VueTheMask);
Vue.use(ElementUI, { locale });
Vue.use(
  new VueSocketio({
    upgrade: false,
    transports: ["websocket"],
    connection: io(process.env.VUE_APP_BASE_URL),
    options: { timeout: 1000 * 60 * 60, autoConnect: false },
  })
);
const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 60 * 60000,
  startAtIdle: false,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
