export default [
  {
    value: 1,
    label: "1-10",
  },
  {
    value: 2,
    label: "11-50",
  },
  {
    value: 3,
    label: "51-100",
  },
  {
    value: 4,
    label: "101-200",
  },
  {
    value: 5,
    label: "201-500",
  },
  {
    value: 7,
    label: "มากกว่า 1,000 คน",
  },
];
