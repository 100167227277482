import { register } from "register-service-worker";
import store from "./store";
import { HTTP } from "./service/axios";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {},
  registered(registration) {
    setTimeout(() => {
      Notification.requestPermission(async function (result) {
        if (result === "granted") {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              process.env.VUE_APP_PUBLIC_V_API_KEY
            ),
          });

          if (typeof store.state.token !== "undefined" && store.state.token) {
            HTTP.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            HTTP.post(`/subscribe/service/worker`, subscription)
            .catch((e) => console.error(e));
          }
        }
      });
    }, 300);
  },
  cached() {},
  updatefound() {},
  updated() {},
  offline() {},
  error(error) {},
});

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
