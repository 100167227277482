export default [
  {
    value: 1,
    label: "Trello",
  },
  {
    value: 2,
    label: "Asana",
  },
  {
    value: 3,
    label: "Monday.com",
  },
  {
    value: 4,
    label: "Jira",
  },
  {
    value: 5,
    label: "Click up",
  },
  {
    value: 7,
    label: "Aritable",
  },
  {
    value: 8,
    label: "Excel",
  },
  {
    value: 9,
    label: "ไม่เคย",
  },
  {
    value: 999,
    label: "อื่นๆ",
  },
];
