import Vue from "vue";

Vue.mixin({
  methods: {
    alertSuccess() {
      this.$notify({
        title: "Success",
        message: "ดำเนินการเสร็จสิ้น",
        position: "top-left",
        type: "success",
      });
    },
    alertFailError() {
      this.$message.error({
        showClose: true,
        message: "ระบบขัดข้อง กรุณาลองใหม่",
        type: "error",
      });
    },
    alertCatchError() {
      this.$message.error({
        showClose: true,
        message: "เครือข่ายไม่เสถียร กรุณาติดต่อผู้ดูแล",
        type: "error",
      });
    },
    alertFileTypeError() {
      this.$message.error({
        showClose: true,
        message: "ระบบไม่รองรับรูปแบบไฟล์",
        type: "error",
      });
    },
    alertUploadFileError() {
      this.$message.error({
        showClose: true,
        message: "อัปโหลดไฟล์ล้มเหลว",
        type: "error",
      });
    },
    alertUploadFileSizeError(size) {
      this.$message.error({
        showClose: true,
        message: `กรุณาอัปโหลดไฟล์ที่มีขนาดไม่เกิน ${size} mb`,
        type: "error",
      });
    },
    alertProjectDuplicateError() {
      this.$message.error({
        showClose: true,
        message: "ใช้ชื่อโปรเจคซ้ำ",
        type: "error",
      });
    },
    alertPermisstionError() {
      this.$message.error({
        showClose: true,
        message: "ไม่มีสิทธิ์ใช้งานในส่วนนี้",
        type: "error",
      });
    },
    alertLessWorkSpaceError() {
      this.$message.error({
        showClose: true,
        message: "ต้องมีมากกว่า 1 เวิร์คสเปซ",
        type: "error",
      });
    },
    alertUserInworkspace() {
      this.$message.error({
        showClose: true,
        message: "ผู้ใช้นี้ได้อยู่ใน Workspace แล้ว",
        type: "error",
      });
    },
    alertMemberPerWorkspaceIsFull() {
      this.$message.error({
        showClose: true,
        message: "จำนวนสมาชิกที่เข้าร่วมได้เต็มแล้ว",
        type: "error",
      });
    },
    alertWorkspaceIsFull() {
      this.$message.error({
        showClose: true,
        message: "จำนวนเวิร์คสเปซที่สร้างได้เต็มแล้ว",
        type: "error",
      });
    },
    alertExpiration() {
      this.$message.error({
        showClose: true,
        message:
          "บัญชีของคุณหมดอายุการใช้งาน กรุณาติดต่อเจ้าหน้าที่เพื่อทำการต่ออายุการใช้งาน",
        type: "error",
      });
    },
    alertBuyPackage() {
      this.$notify({
        title: 'การใช้งานของคุณเป็น Free Subscription',
        dangerouslyUseHTMLString: true,
        message: `<span>สามารถอัปเกรดเป็น Premium User ได้ <a >ที่นี่</a></span>`,
        type: 'warning',
        customClass: 'notify-user',
        onClick: () => {
          this.$store.commit("SET_DIALOG_UPGRADE", true)
        }
      })
    },
    alertPaymentCreditDoNotHonour() {
      this.$message.error({
        showClose: true,
        message: "ข้อมูลบัตรไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
        type: "error",
      });
    },
    alertPaymentCreditInvalidCardNumber() {
      this.$message.error({
        showClose: true,
        message: "หมายเลขบัตรไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
        type: "error",
      });
    },
    alertPaymentCreditCardCanceled() {
      this.$message.error({
        showClose: true,
        message: "บัตรถูกยกเลิก",
        type: "error",
      });
    },
    alertPaymentCreditExpiredCard() {
      this.$message.error({
        showClose: true,
        message: "วันหมดอายุไม่ถูกต้อง กรุณาตรวจสอบข้อมูล",
        type: "error",
      });
    },
    alertPaymentCreditError() {
      this.$message.error({
        showClose: true,
        message: "การชำระเงินล้มเหลว กรุณาลองใหม่",
        type: "error",
      });
    },
    alertPaymentCreditInsufficientFunds() {
      this.$message.error({
        showClose: true,
        message: "การชำระเงินล้มเหลว ยอดเงินของท่านไม่เพียงพอ",
        type: "error",
      });
    },
    alertPaymentCreditAddCardError() {
      this.$message.error({
        showClose: true,
        message: "ไม่สามารถทำรายการได้ กรุณาลองใหม่",
        type: "error",
      });
    },
    alertDelTask() {
      this.$message.success({
        showClose: true,
        message: "ลบทาสก์เรียบร้อยแล้ว",

      });
    },
  },
});
