export default {
  getMembers: (state) => (workspaceId) => {

    if (typeof state.members.admins !== "undefined") {
      return [];
    }

    let find = state.members.find(
      (f) => f.workspaceId === workspaceId
    );
    if (typeof find !== "undefined") {
      return find.members;
    } else {
      return [];
    }
  },
  getTeams: (state) => (workspaceId) => {

    let find = state.teams.find(
      (f) => f.workspaceId === workspaceId
    );
    if (typeof find !== "undefined") {
      return find.teams;
    } else {
      return [];
    }
  },
  getFileList(state) {
    return state.commentFile.filter((filter) => filter.type === "file");
  },
  getWorkspace: (state) => (workspaceId) => {

    let find = state.workspaces.find(
      (f) => f._id === workspaceId
    );
    if (typeof find !== "undefined") {
      return find;
    } else {
      return null;
    }
  },
  getMemberOrganization: (state) => (organizationId) => {

    let find = state.memberOrganization.find(
      (f) => f.organizationId === organizationId
    );
    if (typeof find !== "undefined") {
      return find.members;
    } else {
      return [];
    }
  },
  getUserJoinWorkspace: (state) => (workspaceId) => {

    let find = state.userJoinWorkspace.find(
      (f) => f._id === workspaceId
    );
    if (typeof find !== "undefined") {
      return find.users;
    } else {
      return [];
    }
  },

};
