import Vue from "vue";

Vue.mixin({
  methods: {
    getDayTh(date, format) {
      let day = date.getDay();
      switch (day) {
        case 0:
          return format === "s" ? "อา" : "อาทิตย์";
          break;
        case 1:
          return format === "s" ? "จ" : "จันทร์";
          break;
        case 2:
          return format === "s" ? "อ" : "อังคาร";
          break;
        case 3:
          return format === "s" ? "พ" : "พุธ";
          break;
        case 4:
          return format === "s" ? "พฤ" : "พฤหัสบดี";
          break;
        case 5:
          return format === "s" ? "ศ" : "ศุกร์";
          break;
        case 6:
          return format === "s" ? "ส" : "เสาร์";
          break;
        default:
          break;
      }
    },
    getMonthTh(date, format) {
      let month = date.getMonth();
      switch (month) {
        case 0:
          return format === "s" ? "ม.ค" : "มกราคม";
          break;
        case 1:
          return format === "s" ? "ก.พ" : "กุมภาพันธ์";
          break;
        case 2:
          return format === "s" ? "มี.ค" : "มีนาคม";
          break;
        case 3:
          return format === "s" ? "เม.ย" : "เมษายน";
          break;
        case 4:
          return format === "s" ? "พ.ค" : "พฤษภาคม";
          break;
        case 5:
          return format === "s" ? "มิ.ย" : "มิถุนายน";
          break;
        case 6:
          return format === "s" ? "ก.ค" : "กรกฎาคม";
          break;
        case 7:
          return format === "s" ? "ส.ค" : "สิงหาคม";
          break;
        case 8:
          return format === "s" ? "ก.ย" : "กันยายน";
          break;
        case 9:
          return format === "s" ? "ต.ค" : "ตุลาคม";
          break;
        case 10:
          return format === "s" ? "พ.ย" : "พฤศจิกายน";
          break;
        case 11:
          return format === "s" ? "ธ.ค" : "ธันวาคม";
          break;
        default:
          break;
      }
    },
    notTime(date) {
      return new Date(date).setHours(0, 0, 0, 0);
    },
    getProcessDate(end) {
      if (end !== "") {
        let time = "";

        let diff = Date.now() - new Date(end).getTime();
        let day = diff / (24 * 60 * 60 * 1000);
        let hour = diff / (60 * 60 * 1000);
        let minutes = diff / (60 * 1000);
        let second = diff / 1000;

        if (day >= 7) {
          time = this.$options.filters.dateFomatShortmonthTime(end);
        }
        if (day >= 1) {
          time = parseInt(day) + " วันที่แล้ว";
        } else if (hour >= 1) {
          time = parseInt(hour) + " ชัวโมงที่แล้ว";
        } else if (minutes >= 1) {
          time = parseInt(minutes) + " นาทีที่แล้ว";
        } else {
          time = parseInt(second) + " วินาทีที่แล้ว";
        }

        return "(ออนไลน์เมื่อ " + time + ")";
      } else {
        return "";
      }
    },

    getMonthText(m) {
      let month = m - 1;
      switch (month) {
        case 0:
          return "มกราคม";
          break;
        case 1:
          return "กุมภาพันธ์";
          break;
        case 2:
          return "มีนาคม";
          break;
        case 3:
          return "เมษายน";
          break;
        case 4:
          return "พฤษภาคม";
          break;
        case 5:
          return "มิถุนายน";
          break;
        case 6:
          return "กรกฎาคม";
          break;
        case 7:
          return "สิงหาคม";
          break;
        case 8:
          return "กันยายน";
          break;
        case 9:
          return "ตุลาคม";
          break;
        case 10:
          return "พฤศจิกายน";
          break;
        case 11:
          return "ธันวาคม";
          break;
        default:
          break;
      }
    },
    getRemainingText(date) {
      let now = Date.now();
      let expiration = new Date(date).getTime();
      let time = (expiration - now) / 1000;
      let d = 0;
      let h = 0;
      let m = 0;

      d = parseInt(time / 86400);
      time = time - d * 86400;

      if (time > 0) {
        h = parseInt(time / 3600);
        time = time - h * 3600;

        if (time > 0) {
          m = parseInt(time / 60);
        }
      } else {
        return `0 วัน 0 ชั่วโมง 0 นาที`;
      }

      return `${addUnit(d, 'วัน')}${addUnit(h, 'ชั่วโมง')}${addUnit(m, 'นาที')}`;

      function addUnit(n, u) {
        return n > 0 ? ` ${n} ${u}` : '';
      }
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0);
    },
    getTagDate(dates) {
      if (dates && dates.length > 0) {
        const start = new Date(dates[0])
        const end = new Date(dates[1])

        let firstYear = this.getFirstDayOfMonth(start.getFullYear(), 0)
        let lastYear = this.getLastDayOfMonth(start.getFullYear(), 11)

        let quarter1Start = this.getFirstDayOfMonth(start.getFullYear(), 0)
        let quarter1End = this.getLastDayOfMonth(start.getFullYear(), 2)

        let quarter2Start = this.getFirstDayOfMonth(start.getFullYear(), 3)
        let quarter2End = this.getLastDayOfMonth(start.getFullYear(), 5)

        let quarter3Start = this.getFirstDayOfMonth(start.getFullYear(), 6)
        let quarter3End = this.getLastDayOfMonth(start.getFullYear(), 8)

        let quarter4Start = this.getFirstDayOfMonth(start.getFullYear(), 9)
        let quarter4End = this.getLastDayOfMonth(start.getFullYear(), 11)


        if (start.getTime() === firstYear.getTime() && end.getTime() === lastYear.getTime()) {
          return `ประจำปี - ${start.getFullYear()}`
        } else if (start.getTime() === quarter1Start.getTime() && end.getTime() === quarter1End.getTime()) {
          return `ไตรมาส1 - ${start.getFullYear()}`
        } else if (start.getTime() === quarter2Start.getTime() && end.getTime() === quarter2End.getTime()) {
          return `ไตรมาส2 - ${start.getFullYear()}`
        } else if (start.getTime() === quarter3Start.getTime() && end.getTime() === quarter3End.getTime()) {
          return `ไตรมาส3 - ${start.getFullYear()}`
        } else if (start.getTime() === quarter4Start.getTime() && end.getTime() === quarter4End.getTime()) {
          return `ไตรมาส4 - ${start.getFullYear()}`
        } else {
          return ""
        }

      } else {
        return ""
      }
    },
    getDateShortcuts() {
      var getFirstDayOfMonth = this.getFirstDayOfMonth;
      var getLastDayOfMonth = this.getLastDayOfMonth;

      var quarter = [
        {
          text: `ไตรมาส1 - ${new Date().getFullYear()}`,
          onClick(picker) {
            const start = getFirstDayOfMonth(new Date().getFullYear(), 0);
            const end = getLastDayOfMonth(new Date().getFullYear(), 2);

            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: `ไตรมาส2 - ${new Date().getFullYear()}`,
          onClick(picker) {
            const start = getFirstDayOfMonth(new Date().getFullYear(), 3);
            const end = getLastDayOfMonth(new Date().getFullYear(), 5);

            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: `ไตรมาส3 - ${new Date().getFullYear()}`,
          onClick(picker) {
            const start = getFirstDayOfMonth(new Date().getFullYear(), 6);
            const end = getLastDayOfMonth(new Date().getFullYear(), 8);

            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: `ไตรมาส4 - ${new Date().getFullYear()}`,
          onClick(picker) {
            const start = getFirstDayOfMonth(new Date().getFullYear(), 9);
            const end = getLastDayOfMonth(new Date().getFullYear(), 11);

            picker.$emit("pick", [start, end]);
          },
        },
      ];

      var years = [];

      for (
        let index = new Date().getFullYear();
        index < new Date().getFullYear() + 4;
        index++
      ) {
        const obj = {
          text: `ประจำปี - ${index}`,
          onClick(picker) {
            const start = getFirstDayOfMonth(index, 0);
            const end = getLastDayOfMonth(index, 11);

            picker.$emit("pick", [start, end]);
          },
        };

        years.push(obj);
      }

      const shortcuts = quarter.concat(years);

      return shortcuts
    },
    getDateKey(type) {
      if (type === "week") {
        let day = new Date().getDay()
        return `week${day === 0 ? 7 : day}`
      } else if (type === "month") {
        let month = new Date().getMonth()
        return `month${month + 1}`
      } else {
        return new Date(new Date().setHours(0, 0, 0, 0))
      }
    },
    getRangeDateOfKey(key) {
      if (key.indexOf("week") > -1) {
        return this.getDatDay(key)
      } else if (key.indexOf("month") > -1) {
        return this.getDatMonth(key)
      } else {
        return {
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: new Date(new Date().setHours(23, 59, 59, 999)),
        }
      }
    },
    getDatDay(key) {
      var decrease = 0
      var increate = 0

      switch (key) {
        case "week1":
          decrease = -6
          increate = 1
          break;
        case "week2":
          decrease = -5
          increate = 2
          break;
        case "week3":
          decrease = -4
          increate = 3
          break;
        case "week4":
          decrease = -3
          increate = 4
          break;
        case "week5":
          decrease = -2
          increate = 5
          break;
        case "week6":
          decrease = -1
          increate = 6
          break;
        case "week7":
          decrease = 0
          increate = 7
          break;
        default:
          break;
      }

      var d = new Date();



      var day = d.getDay()
      var diff = d.getDate() - day + (day == 0 ? decrease : increate);
      var date = new Date(d.setDate(diff))

      return {
        startDate: new Date(date.setHours(0, 0, 0, 0)),
        endDate: new Date(date.setHours(23, 59, 59, 999)),
      }
    },
    getDatMonth(key) {
      var month = 0
      var nextMonth = 0

      switch (key) {
        case "month1":
          month = 0
          nextMonth = 1
          break;
        case "month2":
          month = 1
          nextMonth = 2
          break;
        case "month3":
          month = 2
          nextMonth = 3
          break;
        case "month4":
          month = 3
          nextMonth = 4
          break;
        case "month5":
          month = 4
          nextMonth = 5
          break;
        case "month6":
          month = 5
          nextMonth = 6
          break;
        case "month7":
          month = 6
          nextMonth = 7
          break;
        case "month8":
          month = 7
          nextMonth = 8
          break;
        case "month9":
          month = 8
          nextMonth = 9
          break;
        case "month10":
          month = 9
          nextMonth = 10
          break;
        case "month11":
          month = 10
          nextMonth = 11
          break;
        case "month12":
          month = 11
          nextMonth = 12
          break;
        default:
          break;
      }

      return {
        startDate: new Date(new Date().getFullYear(), month, 1),
        endDate: new Date(new Date().getFullYear(), nextMonth, -1),
      }
    }
  },
});
